<script setup>
import { useLayout } from '@/layout/composables/layout';

const { isDarkTheme } = useLayout();
</script>

<template>
    <!-- <div class="layout-footer mt-auto">
        <div class="footer-start">
            <img :src="'/layout/images/logo-' + (isDarkTheme ? 'light' : 'dark') + '.png'" alt="logo" />
            <span class="app-name">Verona</span>
        </div>
        <div class="footer-right">
            <span>© Your Organization</span>
        </div>
    </div> -->
</template>
