<script setup>
import { useLayout } from '@/layout/composables/layout';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useUserSession } from "@/stores/userSession";
import { storeToRefs } from "pinia";

const { toggleMenu, layoutConfig, tabs, closeTab, isDarkTheme, toggleConfigSidebar } = useLayout();

const userSession = useUserSession();
const { user, getActiveBreadcrumb } = storeToRefs(userSession);

const searchActive = ref(false);

const router = useRouter();

function onCloseTab(index) {
    if (tabs.value.length > 1) {
        if (index === tabs.value.length - 1) router.push(tabs.value[tabs.value.length - 2].to);
        else router.push(tabs.value[index + 1].to);
    } else {
        router.push('/');
    }
    closeTab(index);
}

const logo = computed(() => {
    const path = '/layout/images/logo-';

    let logo;
    if (layoutConfig.layoutTheme === 'primaryColor' && layoutConfig.theme !== 'yellow') {
        logo = 'light.png';
    } else {
        logo = isDarkTheme.value ? 'light.png' : 'dark.png';
    }
    return path + logo;
});

const handleLogout = () => {
  userSession.logoutUser();
  router.push("/login");
};

const route = useRoute();
const home = { icon: 'pi pi-home', to: '/' };

const breadcrumbRoutes = ref([]);
const breadcrumbRouteName = ref([]);

const setBreadcrumbRoutes = () => {
  if (route.meta.breadcrumb) {
    breadcrumbRoutes.value = route.meta.breadcrumb;
    return;
  }

  breadcrumbRoutes.value = route.fullPath
    .split('/')
    .filter((item) => item !== '')
    .filter((item) => isNaN(Number(item)))
    .map((item) => item.charAt(0).toUpperCase() + item.slice(1));
};

watch(
  route,
  () => {
    setBreadcrumbRoutes();
  },
  { immediate: true }
);

</script>

<template>
    <div class="layout-topbar">
      <router-link to="/ps-customer-server/list" class="app-logo">
        <img alt="app logo" :src="logo" />
        <span class="app-name">CapMagic</span>
      </router-link>

      <button ref="menubutton" class="topbar-menubutton p-link" type="button" @click="onMenuButtonClick">
        <span></span>
      </button>
      <div class="flex w-full justify-between items-center mr-4 ml-14">
        <nav class="layout-breadcrumb">
          <ol>
            <li>
              <router-link :to="home.to" style="color: inherit">
                <em :class="home.icon"></em>
              </router-link>
            </li>
            <li class="layout-breadcrumb-chevron">/</li>
            <template v-for="(breadcrumbRoute, i) in breadcrumbRoutes" :key="breadcrumbRoute">

              <li v-if="breadcrumbRoutes.length - 1 != i">
                <!-- set breadcrumb icon -->
                <i v-if="!!breadcrumbRoute.icon" :class="breadcrumbRoute.icon" class="mr-2"></i>
                <router-link :to="{ name: breadcrumbRoute.name }">
                  {{ breadcrumbRoute.title }}
                </router-link>
              </li>
              <!-- make sure always clean title when leaving (on unmounted else default value will not work) -->
              <!-- show title from store if not avilable then display normal title if not set then also fallback to below title -->
              <li v-else-if="!!getActiveBreadcrumb">{{ getActiveBreadcrumb }}</li>
              <li v-else>
                <!-- set breadcrumb icon -->
                <i v-if="!!breadcrumbRoute.icon" :class="breadcrumbRoute.icon" class="mr-2"></i>
                {{ breadcrumbRoute.title }}
              </li>

              <li v-if="i !== breadcrumbRoutes.length - 1" class="layout-breadcrumb-chevron">/</li>
            </template>
          </ol>
        </nav>
        <div class="topbar-profile flex">
            <Button severity="contrast" rounded type="button" class="mt-1 mr-1" icon="pi pi-palette" @click="toggleConfigSidebar" />

          <button class="topbar-profile-button ml-2" type="button" v-styleclass="{ selector: '@next', enterFromClass: 'hidden', enterActiveClass: 'animate-scalein', leaveToClass: 'hidden', leaveActiveClass: 'animate-fadeout', hideOnOutsideClick: true }">
            <!-- <img alt="avatar" src="/layout/images/avatar.png" /> -->
            <Avatar label="SE" size="large" shape="circle" />
            <span class="profile-details ml-2">
              <span class="profile-name">{{ user.fullName }}</span>
              <!-- <span class="profile-job">{{ user.email }}</span> -->
            </span>
            <em class="pi pi-angle-down"></em>
          </button>
          <ul
            class="list-none p-3 m-0 rounded-lg shadow-lg hidden absolute bg-white border w-full sm:w-48 mt-2 right-0 top-full z-50">
            <li>
              <a v-ripple @click="handleLogout"
                class="p-ripple flex p-2 rounded-lg items-center hover:bg-gray-100 transition cursor-pointer">
                <em class="pi pi-power-off mr-3"></em>
                <span>Sign Out</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </template>
