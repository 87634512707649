import { createRouter, createWebHistory } from 'vue-router';
import AppLayout from '@/layout/AppLayout.vue';

const routes = [
    {
        path: '/',
        component: AppLayout,
        children: [
            {
                path: '/ps-customer-server/list',
                name: 'ps-customer-server.list',
                meta: {
                    breadcrumb: [{
                        title: 'PS Customer Server',
                        name: 'ps-customer-server.list',
                        icon: ''
                    }]
                },
                component: () => import('@/views/ps-customer-server/List.vue')
            },
            {
                path: '/ps-customer-server/add',
                name: 'ps-customer-server-add',
                meta: {
                    breadcrumb: [{
                        title: 'PS Customer Server',
                        name: 'ps-customer-server.list',
                        icon: ''
                    },
                    {
                        title: 'Add',
                        name: 'ps-customer-server-add',
                        icon: ''
                    }]
                },
                component: () => import('@/views/ps-customer-server/Add.vue')
            },
            // {
            //     path: '/site-assessment/list',
            //     name: 'site-assessment.list',
            //     meta: {
            //         breadcrumb: [
            //             {
            //                 title: 'Site Assessment',
            //                 name: 'site-assessment.list',
            //                 icon: ''
            //             },
            //         ]
            //     },
            //     component: () => import('@/views/site-assessment/List.vue')
            // },
            // {
            //     path: '/site-assessment/add',
            //     name: 'site-assessment.add',
            //     meta: {
            //         breadcrumb: [
            //             {
            //                 title: 'Site Assessment',
            //                 name: 'site-assessment.list',
            //                 icon: ''
            //             },
            //             {
            //                 title: 'Add',
            //                 name: 'site-assessment.add',
            //                 icon: ''
            //             }
            //         ]
            //     },
            //     component: () => import('@/views/site-assessment/Add.vue')
            // },
            //NOTE: New Site Assessment Start
            {
                path: '/site-assessment/list',
                name: 'site-assessment.list',
                meta: {
                    breadcrumb: [
                        {
                            title: 'Site Assessment List',
                            name: 'site-assessment.list',
                            icon: 'pi pi-desktop'
                        },
                    ]
                },
                component: () => import('@/views/new-site-assessment/List.vue')
            },
            {
                path: '/site-assessment/add',
                name: 'site-assessment.add',
                meta: {
                    breadcrumb: [
                        {
                            title: 'Site Assessment List',
                            name: 'site-assessment.list',
                            icon: 'pi pi-desktop'
                        },
                        {
                            title: 'Add',
                            name: 'site-assessment.add',
                            icon: ''
                        }
                    ]
                },
                component: () => import('@/views/new-site-assessment/Add.vue')
            },
            {
                path: '/site-assessment/view/:id',
                name: 'site-assessment.view',
                meta: {
                    breadcrumb: [
                        {
                            title: 'Site Assessment List',
                            name: 'site-assessment.list',
                            icon: 'pi pi-desktop'
                        },
                        {
                            title: 'View',
                            name: 'site-assessment.view',
                            icon: ''
                        }
                    ]
                },
                component: () => import('@/views/new-site-assessment/View.vue')
            },
            //NOTE: New Site Assessment End
            {
                path: '/transformer/list',
                name: 'transformer.list',
                meta: {
                    breadcrumb: [
                        {
                            title: 'Transformer',
                            name: 'site-assessment.transformer',
                            icon: ''
                        },
                    ]
                },
                component: () => import('@/views/transformer/List.vue')
            },
            {
                path: '/ddi-library/list',
                name: 'ddi-library.list',
                meta: {
                    breadcrumb: [
                        {
                            title: 'DDI Library',
                            name: 'ddi-library.list',
                            icon: ''
                        },
                    ]
                },
                component: () => import('@/views/ddi-library/List.vue')
            },
            {
                path: '/master-variable/list',
                name: 'master-variable.list',
                meta: {
                    breadcrumb: [
                        {
                            title: 'Master Variable',
                            name: 'master-variable.list',
                            icon: ''
                        },
                    ]
                },
                component: () => import('@/views/master-variable/List.vue')
            },
            {
                path: '/data-sync/list',
                name: 'data-sync.list',
                meta: {
                    breadcrumb: [
                        {
                            title: 'Data Sync',
                            name: 'data-sync.list',
                            icon: ''
                        },
                    ]
                },
                component: () => import('@/views/data-sync/List.vue')
            },
            {
                path: '/gateway-management/index',
                name: 'gateway-management.index',
                meta: {
                    breadcrumb: [
                        {
                            title: 'Gateway Management',
                            name: 'gateway-management.index',
                            icon: ''
                        },
                    ]
                },
                component: () => import('@/views/gateway-management/List.vue')
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/pages/auth/Login.vue')
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/views/pages/404.vue'),
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/404'
    },
    {
        path: '/saml/acs-verify-token',
        name: "token",
        component: () => import('@/views/pages/auth/Callback.vue'),
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { left: 0, top: 0 };
    }
});

export default router;
