<template>
  <!-- <splashLoader /> -->
  <router-view />
  <Toast group="message" :pt="{
            messageIcon: {
                style: {'margin-top' : '3px'}
            }
        }" />
</template>

<script>
import splashLoader from './components/splashLoader.vue';

</script>

<style>
::-webkit-scrollbar {
    width: 7px !important;
    height: 7px !important;
}

/* Track */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    /* background-color: #babac0; */
    background-color: var(--primary-color);
    min-height: 30px;
}
</style>
