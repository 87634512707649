<script setup>
import { useLayout } from '@/layout/composables/layout';
import { computed, onBeforeUnmount, watch, ref } from 'vue';
import AppBreadcrumb from './AppBreadcrumb.vue';
import AppConfig from './AppConfig.vue';
import AppFooter from './AppFooter.vue';
import AppSidebar from './AppSidebar.vue';
import AppTopbar from './AppTopbar.vue';

const { layoutConfig, layoutState, isSidebarActive } = useLayout();

const outsideClickListener = ref(null);

function bindOutsideClickListener() {
    if (!outsideClickListener.value) {
        outsideClickListener.value = (event) => {
            if (isOutsideClicked(event)) {
                layoutState.overlayMenuActive = false;
                layoutState.overlaySubmenuActive = false;
                layoutState.staticMenuMobileActive = false;
                layoutState.menuHoverActive = false;
                layoutState.configSidebarVisible = false;
            }
        };
        document.addEventListener('click', outsideClickListener.value);
    }
}

function unbindOutsideClickListener() {
    if (outsideClickListener.value) {
        document.removeEventListener('click', outsideClickListener.value);
        outsideClickListener.value = null;
    }
}

function isOutsideClicked(event) {
    const sidebarEl = document.querySelector('.layout-sidebar');
    const topbarEl = document.querySelector('.topbar-menubutton');

    return !(sidebarEl.isSameNode(event.target) || sidebarEl.contains(event.target) || topbarEl.isSameNode(event.target) || topbarEl.contains(event.target));
}

watch(isSidebarActive, (newVal) => {
    if (newVal) {
        bindOutsideClickListener();
    } else {
        unbindOutsideClickListener();
    }
});

onBeforeUnmount(() => {
    unbindOutsideClickListener();
});

const containerClass = computed(() => {
    return [
        {
            'layout-light': layoutConfig.layoutTheme === 'colorScheme' && !layoutConfig.darkTheme,
            'layout-dark': layoutConfig.layoutTheme === 'colorScheme' && layoutConfig.darkTheme,
            'layout-primary': !layoutConfig.darkTheme && layoutConfig.layoutTheme === 'primaryColor',
            'layout-slim': layoutConfig.menuMode === 'slim',
            'layout-slim-plus': layoutConfig.menuMode === 'slim-plus',
            'layout-static': layoutConfig.menuMode === 'static',
            'layout-overlay': layoutConfig.menuMode === 'overlay',
            'layout-overlay-active': layoutState.overlayMenuActive,
            'layout-mobile-active': layoutState.staticMenuMobileActive,
            'layout-static-inactive': layoutState.staticMenuDesktopInactive && layoutConfig.menuMode === 'static'
        }
    ];
});
</script>

<template>
    <div class="layout-container" :class="containerClass">
        <AppTopbar></AppTopbar>
        <AppSidebar></AppSidebar>
        <div class="layout-content-wrapper">
            <div class="layout-content !bg-blue-50">
                <div class="layout-content-inner">
                    <!-- <AppBreadcrumb></AppBreadcrumb> -->
                    <router-view></router-view>
                    <AppFooter></AppFooter>
                </div>
            </div>
        </div>
        <Toast :pt="{
            messageIcon: {
                style: {'margin-top' : '3px'}
            }
        }" />
        <AppConfig></AppConfig>
    </div>
</template>

<style lang="scss"></style>
